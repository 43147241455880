import styled from 'styled-components'

export const Section = styled.section`
  background-color: var(--white-color);
`

export const Container = styled.div`
  justify-items: center;
  row-gap: 2rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 2rem;
  }
`

export const Image = styled.div`
  position: relative;
  overflow: hidden;

  img {
    border-radius: 0.5rem;
  }

  :hover .link {
    bottom: 0;
  }
`

export const Link = styled.div`
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  cursor: pointer;
  backdrop-filter: blur(10px);
  transition: 0.3s;
`

export const LinkName = styled.a`
  color: var(--dark-color);
`
