import styled from 'styled-components'

export const Section = styled.section``

export const Title = styled.h2``

export const Container = styled.div`
  justify-items: center;
  row-gap: 2rem;
  text-align: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: initial;
    padding: 4rem 0;
  }
`

export const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 110px;
  overflow: hidden;

  img {
    border-radius: 50%;
    width: 100px;
  }

  @media screen and (min-width: 768px) {
    width: 165px;
    height: 180px;

    img {
      width: 165px;
    }
  }
`

export const Subtitle = styled.h2`
  font-size: var(--h2-font-size);
  color: var(--first-color);
  margin-bottom: var(--mb-1);
`

export const Text = styled.p`
  margin-bottom: var(--mb-4);
`

export const Profession = styled.span`
  display: block;
  margin-bottom: var(--mb-4);
`

export const SocialIcon = styled.a`
  font-size: 1.4rem;
  margin: 0 var(--mb-1);

  :hover {
    color: var(--first-color);
  }
`
