import React from 'react'

/**
 * Styles.
 */
import { Section, Container, Title, Scroll, ScrollLink, Image } from './styles'

/**
 * Icons.
 */
import { AiOutlineArrowUp } from 'react-icons/ai'

/**
 * Assets.
 */
import myPhoto from '../../../assets/img/perfil.png'

/**
 * Component.
 */
export function Home() {
  return (
    <Section id="home">
      <Container className="bd-grid">
        <Title>
          <span>BEM</span>
          <br />
          VINDO.
        </Title>

        <Scroll>
          <ScrollLink href="#about">
            <AiOutlineArrowUp />
            Rolar para baixo
          </ScrollLink>
        </Scroll>

        <Image src={myPhoto} alt="" />
      </Container>
    </Section>
  )
}
