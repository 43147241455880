import styled from 'styled-components'

export const Section = styled.section``

export const Container = styled.div`
  row-gap: 2rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
`

export const Subtitle = styled.h3`
  color: var(--first-color);
  margin-bottom: var(--mb-3);
`

export const SkillName = styled.span`
  display: inline-block;
  font-size: var(--small-font-size);
  margin-right: var(--mb-2);
  margin-bottom: var(--mb-3);
  padding: 0.25rem 0.5rem;
  background-color: var(--white-color);
  border-radius: 0.25rem;

  :hover {
    background-color: var(--first-color);
    color: var(--white-color);
  }
`

export const Image = styled.div`
  img {
    border-radius: 0.5rem;
  }
`
