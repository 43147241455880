import React from 'react'

/**
 * Components.
 */
import { Home } from '../../components/Home/components'
import { About } from '../../components/About/components'
import { Footer } from '../../components/Footer/components'
import { Header } from '../../components/Header/components'
import { Skills } from '../../components/Skills/components'
import { Projects } from '../../components/Projects/components'
import { Container } from './styles'

/**
 * Component.
 */
export function HomePage() {
  return (
    <>
      <Header />
      <Container>
        <Home />
        <About />
        <Skills />
        <Projects />
      </Container>
      <Footer />
    </>
  )
}
