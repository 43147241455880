import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *,::before,::after {
    box-sizing: border-box;
  }
  
  html{
    scroll-behavior: smooth;
  }

  body{
    margin: var(--header-height) 0 0 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
    line-height: 1.6;

    @media screen and (min-width: 768px){
      margin: 0;
    }
  }

  h1,h2,p{
    margin: 0;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a{
    text-decoration: none;
    color: var(--text-color);
  }

  img{
    max-width: 100%;
    height: auto;
    display: block;
  }

  .section{
    padding: 3rem 0;

    @media screen and (min-width: 768px){
      padding-top: 4rem;
    }
  }

  .section-title{
    position: relative;
    font-size: var(--h2-font-size);
    color: var(--dark-color);
    margin: var(--mb-4) 0;
    text-align: center;

    ::after{
      position: absolute;
      content: "";
      width: 32px;
      height: .18rem;
      left: 0;
      right: 0;
      margin: auto;
      top: 2rem;
      background-color: var(--first-color);
    }

    @media screen and (min-width: 768px){
      margin-bottom: 3rem;

      ::after{
        width: 64px;
        top: 3rem;
      }
    }
  }

  @media screen and (min-width: 768px){
    .active::after{
      background-color: var(--white-color);
    }
  }

  @media screen and (min-width: 1024px){
    .bd-grid {
        margin-left: auto;
        margin-right: auto;
    }
  } 
`
