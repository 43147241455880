// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: 'Light',

  colors: {
    primary: '#5361FF',
    secondary: '#1f202e',

    background: '#f5f5f5',
    text: '#1f202e'
  }
}
