import React from 'react'

/**
 * Styles.
 */
import {
  Section,
  Title,
  Container,
  Image,
  Subtitle,
  Profession,
  Text,
  SocialIcon
} from './styles'

/**
 * Icons.
 */
import { AiFillLinkedin, AiFillGithub, AiFillInstagram } from 'react-icons/ai'

/**
 * Assets.
 */
import mePhoto from '../../../assets/img/me.png'

/**
 * Component.
 */
export function About() {
  return (
    <Section className="section" id="about">
      <Title className="section-title">Sobre</Title>

      <Container className="bd-grid">
        <Image>
          <img src={mePhoto} alt="" />
        </Image>

        <div>
          <Subtitle>Eu sou Anderson Espindola</Subtitle>
          <Profession className="about__profession">
            Desenvolvedor Full-stack
          </Profession>
          <Text className="about__text">
            Sou estudante de Análise e desenvolvimento de sistemas, entusiasta
            em tecnologia, sempre em busca de conhecimento e desafios.
          </Text>

          <div className="about__social">
            <SocialIcon
              href="https://www.linkedin.com/in/anderson-espindola/"
              target="_blank"
            >
              <AiFillLinkedin />
            </SocialIcon>
            <SocialIcon
              href="https://github.com/andersonespindola"
              target="_blank"
            >
              <AiFillGithub />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/espindola.andi/"
              target="_blank"
            >
              <AiFillInstagram />
            </SocialIcon>
          </div>
        </div>
      </Container>
    </Section>
  )
}
