import React from 'react'

/**
 * Styles.
 */
import { Section, Container, Image, Link, LinkName } from './styles'

/**
 * Assets.
 */
import project1 from '../../../assets/img/projeto1.jpg'
import project2 from '../../../assets/img/projeto2.jpg'
import project3 from '../../../assets/img/projeto3.jpg'
import project4 from '../../../assets/img/projeto4.jpg'
import project5 from '../../../assets/img/projeto5.jpg'
import project6 from '../../../assets/img/projeto6.jpg'

/**
 * Component.
 */
export function Projects() {
  return (
    <Section className="section" id="portfolio">
      <h2 className="section-title">Projetos</h2>

      <Container className="bd-grid">
        <Image>
          <img src={project1} alt="" />

          <Link className="link">
            <LinkName href="http://challenger-8.surge.sh/" target="_blank">
              Interface de Evento musical
            </LinkName>
          </Link>
        </Image>
        <Image className="portfolio__img">
          <img src={project2} alt="" />

          <Link className="link">
            <LinkName
              href="https://fast-keyboard-challenge.vercel.app/"
              target="_blank"
            >
              Keyboard Hero Game
            </LinkName>
          </Link>
        </Image>
        <Image className="portfolio__img">
          <img src={project3} alt="" />

          <Link className="link">
            <LinkName
              href="https://random-password-creator.netlify.app/"
              target="_blank"
            >
              Gerador de senhas
            </LinkName>
          </Link>
        </Image>
        <Image className="portfolio__img">
          <img src={project4} alt="" />

          <Link className="link">
            <LinkName href="http://turbo-balls.surge.sh/" target="_blank">
              Bouncing Balls
            </LinkName>
          </Link>
        </Image>
        <Image className="portfolio__img">
          <img src={project5} alt="" />

          <Link className="link">
            <LinkName href="https://josiane-sarda.netlify.app/" target="_blank">
              Netlify Blog
            </LinkName>
          </Link>
        </Image>
        <Image className="portfolio__img">
          <img src={project6} alt="" />

          <Link className="link">
            <LinkName href="https://minis.vercel.app/" target="_blank">
              Encurtador de URL's
            </LinkName>
          </Link>
        </Image>
      </Container>
    </Section>
  )
}
