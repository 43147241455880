export const skillsList = [
  'Html',
  'Css',
  'Javascript',
  'Node',
  'React',
  'GraphQl',
  'Typescript',
  'MongoDB',
  'MySQL',
  'Docker',
  'PHP',
  'Git',
  'Styled Components',
  'Bootstrap',
  'Material UI',
  'Semantic',
  'Jest'
]
