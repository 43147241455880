import React from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'

import { HomePage } from './pages/HomePage'

/**
 * Enabled routes.
 */
export function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
      </Switch>
    </Router>
  )
}
