import React from 'react'

/**
 * Styles.
 */
import {
  Container,
  Navegation,
  NavLogo,
  NavMenu,
  NavList,
  NavItem,
  NavLink,
  NavToggle
} from './styles'

/**
 * Icons.
 */
import { AiOutlineMenu } from 'react-icons/ai'

/**
 * Component.
 */
export function Header() {
  return (
    <Container>
      <Navegation className="bd-grid">
        <div>
          <NavLogo href="#">Anderson Espindola</NavLogo>
        </div>

        <NavMenu id="nav-menu">
          <NavList>
            <NavItem>
              <NavLink href="#home" className="active">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#about">Sobre</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#skills">Conhecimentos</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#portfolio">Projetos</NavLink>
            </NavItem>
          </NavList>
        </NavMenu>

        <NavToggle id="nav-toggle">
          <AiOutlineMenu />
        </NavToggle>
      </Navegation>
    </Container>
  )
}
