import React from 'react'

/**
 * Styles.
 */
import { Section, Container, Subtitle, SkillName, Image } from './styles'

/**
 * Assets.
 */
import skills from '../../../assets/img/skill.jpg'

/**
 * Skills.
 */
import { skillsList } from '../../../data/skills'

/**
 * Component.
 */
export function Skills() {
  return (
    <Section className="section" id="skills">
      <h2 className="section-title">Conhecimentos</h2>

      <Container className="bd-grid">
        <div className="skills__box">
          <Subtitle>Desenvolvimento</Subtitle>

          {skillsList &&
            skillsList.map((skill, index) => (
              <SkillName key={index}>{skill}</SkillName>
            ))}
        </div>

        <Image>
          <img src={skills} alt="" />
        </Image>
      </Container>
    </Section>
  )
}
