import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--first-color);
`

export const Navegation = styled.nav`
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    height: calc(var(--header-height) + 1rem);
  }
`

export const NavLogo = styled.a`
  color: var(--white-color);
`

export const NavMenu = styled.div`
  @media screen and (max-width: 768px) {
    position: fixed;
    top: var(--header-height);
    right: -100%;
    width: 80%;
    height: 100%;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.5s;
    backdrop-filter: blur(10px);
  }

  .show {
    right: 0;
  }
`

export const NavList = styled.ul`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`

export const NavItem = styled.li`
  margin-bottom: var(--mb-4);

  @media screen and (min-width: 768px) {
    margin-left: var(--mb-4);
    margin-bottom: 0;
  }
`

export const NavLink = styled.a`
  position: relative;
  color: var(--dark-color);

  :hover {
    color: var(--first-color);
  }

  /*Active menu*/
  .active::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 0.18rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
  }

  @media screen and (min-width: 768px) {
    color: var(--white-color);

    :hover {
      color: var(--white-color);
    }
  }
`

export const NavToggle = styled.div`
  color: var(--white-color);
  font-size: 1.5rem;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    display: none;
  }
`
