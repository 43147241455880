import React from 'react'

/**
 * Styles.
 */
import {
  Link,
  Title,
  Social,
  Section,
  Container,
  DataContainer
} from './styles'

/**
 * Icons.
 */
import { AiFillLinkedin, AiFillGithub, AiFillInstagram } from 'react-icons/ai'

/**
 * Component.
 */
export function Footer() {
  return (
    <Section className="section">
      <Container className="footer__container bd-grid">
        <DataContainer>
          <Title>Anderson Espindola</Title>
          <p className="footer__text">
            Eu sou Anderson Espindola e esse é um website para mostrar alguns de
            meus projetos.
          </p>
        </DataContainer>

        <DataContainer>
          <Title>EXPLORE</Title>
          <ul>
            <li>
              <Link href="#home">Home</Link>
            </li>
            <li>
              <Link href="#about">Sobre</Link>
            </li>
            <li>
              <Link href="#skills">Conhecimentos</Link>
            </li>
            <li>
              <Link href="#portfolio">Projetos</Link>
            </li>
          </ul>
        </DataContainer>

        <DataContainer>
          <h2 className="footer__title">REDES SOCIAIS</h2>
          <Social
            href="https://www.linkedin.com/in/anderson-espindola/"
            target="_blank"
          >
            <AiFillLinkedin />
          </Social>
          <Social href="https://github.com/andersonespindola" target="_blank">
            <AiFillGithub />
          </Social>
          <Social
            href="https://www.instagram.com/espindola.andi/"
            target="_blank"
          >
            <AiFillInstagram />
          </Social>
        </DataContainer>
      </Container>
    </Section>
  )
}
