import styled from 'styled-components'

export const Section = styled.footer`
  background-color: var(--dark-color);
`

export const Container = styled.div`
  row-gap: 2rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
`

export const Title = styled.h2`
  font-size: var(--normal-font-size);
  color: var(--white-color);
  margin-bottom: var(--mb-2);
`

export const Link = styled.a`
  padding: 0.25rem 0;

  :hover {
    color: var(--first-color);
  }
`

export const Social = styled.a`
  font-size: 1.4rem;
  margin-right: var(--mb-1);

  :hover {
    color: var(--first-color);
  }
`

export const DataContainer = styled.div``
