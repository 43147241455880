import styled from 'styled-components'

export const Section = styled.section`
  position: relative;
  background-color: var(--first-color);
  overflow: hidden;
`

export const Container = styled.div`
  height: calc(100vh - var(--header-height));
  row-gap: 5rem;

  @media screen and (min-height: 721px) {
    height: 640px;
  }

  @media screen and (min-width: 768px) {
    height: 100vh;
    grid-template-rows: 1.7fr 1fr;
    row-gap: 0;
  }
`

export const Title = styled.h1`
  align-self: flex-end;
  font-size: var(--big-font-size);
  color: var(--white-color);
  line-height: 0.8;

  span {
    text-shadow: 0 20px 25px rgba(0, 0, 0, 0.5);
  }
`

export const Scroll = styled.div`
  align-self: flex-end;
  padding-bottom: var(--mb-4);
`

export const ScrollLink = styled.a`
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  color: var(--white-color);
`

export const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 295px;

  @media screen and (max-width: 320px) {
    width: 230px;
  }

  @media screen and (min-height: 721px) {
    width: 500px;
    right: 24%;
  }

  @media screen and (min-width: 768px) {
    width: 524px;
    right: 10%;
  }
`
